@import url('https://fonts.googleapis.com/css?family=Bungee+Inline');
$red: #8c3817;
$black: #222;
$borderWidth: 4px;
$paddingY: 0.35em;
$sigFont: 'Bungee Inline', sans-serif;

.lams {
  .cart.minimized {
    background: #eeeeeb;
    border-bottom: 2px solid #555;
  }
  a.checkout-button.button {
    font-family: 'Bungee Inline', sans-serif;
    background: red;
    font-weight: 400;
    box-shadow: none;
    // border: 2px solid #5a7e2a;
  }
  .pages-nav {
    font-family: $sigFont;
    background: none;
    font-weight: 400;
    ul {
      display: flex;
      flex-wrap: wrap;
    }

    @media screen and (max-width: 480px) {
      font-size: 4vw;
    }
  }

  a.page-link {
    text-decoration: none;
    color: black;
    display: block;
    padding: $paddingY 0.3em;
    padding: $paddingY 0.3em calc(#{$paddingY} - #{$borderWidth}) 0.3em;
    // box-sizing: border-box;
    // border-bottom: $borderWidth solid white;
    &:hover, &.active {
      color: $red;
      // border-bottom: $borderWidth solid black;
    }
  }


  .category-name .text {
    font-family: 'Bungee Inline', sans-serif;
    font-weight: 400;
    font-size: 1.8em;
    background: white;
    // border: 4px solid #5a7e2a;
    // border-radius: 1em;
    color: black;
  }
  // .category-name .text::before {
  //   content: " "
  // }

  .order-benefits {
    /* color: white; */
    /* text-shadow: 0px 0px 4px rgba(255,255,255,1); */
    padding: 1em 0;
    margin: 0;
    // margin-top: -2.5em;
    display: block;
    min-height: 250px;
    /* max-height: 50vh; */
    // background: url(https://image.shutterstock.com/z/stock-photo-greek-food-background-meze-gyros-souvlaki-fish-pita-greek-salad-tzatziki-assortment-of-feta-646209769.jpg);
    background: url(https://d2abh92eaqfsaa.cloudfront.net/image/fetch/c_fill,w_1200/https://dl.airtable.com/qhLMinTrQ8qC9OdCeJt7_Capture0054.jpg);
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    &.catering {
      background: url(https://d2abh92eaqfsaa.cloudfront.net/image/fetch/c_fill,w_1200/https://dl.airtable.com/S3CESg0AS0WhXLouvPKk_Capture0030.jpg) no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }
  .order-benefits .text {
    color: white;
    background: rgba(0,0,0,0.5);
    padding: 0.5em 0.8em;
  }
  .menu-category-list-view {
    .photo-container {
      width: 200px;
    }
  }

  .full-menu-link-wrapper {
    text-align: center;
    a {
      // background: ;
      // color: white;
    }
    margin: 1em 0 2em 0;
  }
}
